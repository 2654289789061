<template>
  <div style="width: 100%; height: 520px; overflow: auto">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.text"
        :name="item.num"
      >
      </el-tab-pane>
    </el-tabs>
    <div
      ref="lineChart"
      style="width: 100%; min-width: 600px; height: 460px"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "sankeyData",
  watch: {
    activeName() {
      this.initChart();
    },
  },
  data() {
    return {
      list: "",
      activeName: "topTrade30",
      chartData: [],
      links: [],
      tabList: [
        {
          text: "近1个月",
          num: "topTrade30",
        },
        {
          text: "近3个月",
          num: "topTrade90",
        },
        {
          text: "近半年",
          num: "topTrade180",
        },
        {
          text: "近1年",
          num: "topTrade365",
        },
      ],
    };
  },
  mounted() {
    // this.initData()
  },
  methods: {
    initData(data) {
      console.log(data, "sankeyData");
      this.list = data;
      this.tabList.forEach((key) => {
        key.list = [];
        data.forEach((item) => {
          if (key.num == item.type) {
            key.list.push(item);
          }
        });
      });
      this.initChart();
    },
    initChart() {
      let dataList = [];
      this.chartData = [];
      this.links = [];
      this.tabList.forEach((item) => {
        if (item.num == this.activeName) {
          dataList = item.list
            .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
            .slice(0, 21);
        }
      });
      let platformObj = {
        WX: "微信",
        ALIPAY: "支付宝",
      };
      let isWX = false;
      let isZfb = false;
      let nameCount = {};
      let name = ''
      dataList.forEach((item) => {
        name = `(${item.amount})${item.tradeName}`
         // 检查该 name 是否已经出现过
         if (nameCount[name]) {
          // 如果出现过，给这个元素加上一个唯一的标识
          name = `(${item.amount})${item.tradeName}-${nameCount[item.name]}`;
          nameCount[name]++;
        } else {
          // 如果是第一次出现这个 name，初始化计数
          nameCount[name] = 1;
        }
        this.chartData.push({ name: name });
        this.links.push({
          source:
            item.amount > 0
              ? name
              : platformObj[item.platform],
          target:
            item.amount > 0
              ? platformObj[item.platform]
              : name,
          value: Math.abs(item.amount),
        });
        if (item.platform == "WX") {
          isWX = true;
        }
        if (item.platform == "ALIPAY") {
          isZfb = true;
        }
      });
      if (isWX) {
        this.chartData.push({ name: "微信" });
      }
      if (isZfb) {
        this.chartData.push({ name: "支付宝" });
      }
      // console.log(this.chartData);

      this.initLineChart(
        this.chartData.filter(
          (item, index) => this.chartData.indexOf(item) === index
        ),
        this.links
      );
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    initLineChart(data, links) {
      // console.log(data, links, "{ data, links }");
      const chartDom = this.$refs.lineChart;
      const myChart = echarts.init(chartDom);
      const option = {
        series: {
          type: "sankey",
          layout: "none",
          emphasis: {
            focus: "adjacency",
          },
          data,
          links,
        },
      };
      // console.log(option)
      myChart.setOption(option);
    },
  },
};
</script>
