<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-if="isShow"
    style="padding: 20px; height: 95vh; overflow: auto"
    v-loading="loading"
  >
    <div v-if="isData">
      <el-row :gutter="10" justify="center">
        <el-col :span="24" :sm="14" :lg="14" style="margin-bottom: 10px">
          <el-card>
            <div class="title_box">方案一</div>
            <div class="first_box">
              <img
                v-if="posterUrl"
                @click="previewVisible = true"
                class="first_img"
                :src="posterUrl ? posterUrl : '@/assets/sample_img.png'"
              />
              <img
                v-if="!posterUrl"
                @click="previewVisible = true"
                class="first_img"
                src="@/assets/sample_img.png"
              />
              <div class="first_right">
                <div class="text_box">
                  <div class="text_title" style="margin-bottom: 10px">
                    <div>使用引导界面引导客户:</div>
                    <div class="copy_text" @click="copyFun(1)">复制话术</div>
                  </div>
                  <div style="font-size: 13px; margin-bottom: 10px">
                    麻烦提供最近12个月的账单，操作步骤如下：
                  </div>
                  <div class="mb-1" style="font-size: 13px">
                    为了更好给您提供服务，请提供下【微信】【支付宝】最近12个月的账单
                  </div>
                  <div class="mb-1" style="font-size: 13px">
                    具体操作教程，请扫描海报的二维码查看投递。
                  </div>
                </div>
                <div
                  class="copy_text"
                  style="margin-right: 5px"
                  @click="downloadPic"
                >
                  下载图片
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24" :sm="14" :lg="14" style="margin-bottom: 10px">
          <el-card>
            <div class="title_box">方案二</div>
            <div class="first_box">
              <div class="text_box">
                <div class="text_title">
                  <div>使用文字版引导话术引导客户发送账单:</div>
                  <div class="copy_text" @click="copyFun(2)">复制话术</div>
                </div>
                <div style="margin-left: 10px; margin-top: 10px">
                  <div style="font-size: 13px; margin-bottom: 8px">
                    麻烦提供最近12个月账单，操作步骤如下:
                  </div>
                  <div style="font-size: 13px; font-weight: 600">
                    微信操作方法:
                  </div>
                  <div class="mb-1" style="font-size: 13px; margin-bottom: 8px">
                    打开微信钱包 -> 点进去右上角“账单” -> 右上 角“常见问题” ->
                    “下载账单” -> "用作证明材料"(一定要选这个) -> “自定义”
                    选择最近近12个月 -> 发送至邮箱
                    <span style="font-weight: bold">{{ account }}</span>
                  </div>
                  <div style="font-size: 13px; font-weight: 600">
                    支付宝操作方法:
                  </div>
                  <div class="mb-1" style="font-size: 13px">
                    点击右下角我的 -> 点击账单 -> 点击右上角“…” -> “开具交易流
                    水证明” -> "用于证明材料"(一定要选这个) ->
                    申请交易类型选择全部交易，时间范围选择最近12个月 ->
                    点“下一步” -> 发送至
                    <span style="font-weight: bold">{{ account }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10" justify="center">
        <el-col :span="24" :sm="14" :lg="7" style="margin-bottom: 10px">
          <el-card class="dashboard-bar-chart">
            <div class="title_box" style="width: 100%">
              <div>微信教程</div>
              <div class="title_link" @click="downloadVideo(1)">下载视频</div>
            </div>
            <div v-if="!wxPlayShow" class="img_box">
              <img
                class="bofang_img"
                @click="wxPlay"
                src="@/assets/bofang.png"
                alt=""
              />
              <img style="height: 600px" :src="wxImg" alt="" />
            </div>
            <!--  -->
            <video
              v-show="wxPlayShow"
              ref="videoPlayer"
              height=""
              controls
              style="border-radius: 10px; height: 600px"
              :src="wxMp4"
            ></video>
          </el-card>
        </el-col>
        <el-col :span="24" :sm="14" :lg="7" style="margin-bottom: 10px">
          <el-card class="dashboard-bar-chart">
            <div class="title_box" style="width: 100%">
              <div>支付宝教程</div>
              <div class="title_link" @click="downloadVideo(2)">下载视频</div>
            </div>
            <div v-if="!aliPlayShow" class="img_box">
              <img
                class="bofang_img"
                @click="aliPlay"
                src="@/assets/bofang.png"
                alt=""
              />
              <img style="height: 600px" :src="zfbImg" alt="" />
            </div>
            <video
              v-show="aliPlayShow"
              ref="aliVideoPlayer"
              height="600px"
              controls
              style="border-radius: 10px; height: 600px"
              :src="zfbMp4"
            ></video>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div
      v-else
      style="
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      "
    >
      <img
        style="max-width: 100%; max-height: 100%"
        src="https://rp-data.1japp.com/dongcha/modal-bg.jpg"
        alt=""
      />
    </div>

    <el-dialog
      v-model="previewVisible"
      :footer="null"
      :before-close="handleCancel"
    >
      <div
        style="
          height: 80vh;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          style="height: 100%"
          :src="posterUrl ? posterUrl : '@/assets/sample_img.png'"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPosterUrl } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      loading: false,
      isShow: false,
      isData: true,
      wxPlayShow: false,
      aliPlayShow: false,
      wxImg: "https://rp-img.1japp.com/mp4/wx1.jpg",
      zfbImg: "https://rp-img.1japp.com/mp4/zfb1.jpg",
      wxMp4: "https://rp-img.1japp.com/mp4/wxf100800.mp4",
      zfbMp4: "https://rp-img.1japp.com/mp4/zfbf100800.mp4",
      posterUrl: "",
      previewVisible: false,
      account: "",
      userInfo: {},
      text1: "",
      text2: "",
      id: "",
      accountId: "",
    };
  },
  created() {},
  mounted() {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      this.text1 =
        "使用引导界面引导客户麻烦提供最近12个月的账单，操作步骤如下：为了更好给您提供服务，请提供下【微信】【支付宝】最近12个月的账单具体操作教程，请扫描海报的二维码查看投递。";
      this.account = this.userInfo.bizEmail;
      this.text2 = `麻烦提供最近12个月账单，操作步骤如下：微信操作方法：打开微信钱包 -> 点进去右上角“账单” -> 右上 角“常见问题”  -> “下载账单” -> "用作证明材料"(一定要选这个) -> “自定义” 选择最近近12个月 ->  发送至邮箱  ${this.account}
      支付宝操作方法：点击右下角我的 -> 点击账单 -> 点击右上角“…” ->  “开具交易流 水证明” -> "用于证明材料"(一定要选这个) -> 申请交易类型选择全部交易，时间范围选择最近12个月 -> 点“下一步” -> 发送至${this.account})`;
    }

    const params = new URLSearchParams(window.location.search);
    this.id = params.get("id");
    this.accountId = params.get("accountId");
    this.isShow = false;
    this.loading = true;
    this.getPosterUrl();
  },
  methods: {
    GetUrlRequest() {
      let url = window;
      let pattern = /([^?&=]+)=([^&]+)/g;
      let params = {};

      // match用于存储正则匹配的结果
      let match;
      // while 循环和正则表达式 exec 方法来迭代匹配URL中的参数
      while ((match = pattern.exec(url)) !== null) {
        // 在字符串url中循环匹配pattern，并对每个匹配项进行解码操作，将解码后的键和值分别存储在key和value变量中
        let key = decodeURIComponent(match[1]);
        let value = decodeURIComponent(match[2]);

        if (params[key]) {
          if (Array.isArray(params[key])) {
            params[key].push(value);
          } else {
            params[key] = [params[key], value];
          }
        } else {
          // 参数名在 params 对象中不存在，将该参数名和对应的值添加到 params 对象中
          params[key] = value;
        }
      }

      if (!paramName) {
        // 没有传入参数名称, 返回含有所有参数的对象params
        return params;
      } else {
        if (params[paramName]) {
          return params[paramName];
        } else {
          return "";
        }
      }
    },
    getPosterUrl() {
      getPosterUrl({ id: this.accountId })
        .then((res) => {
          // console.log(res);
          this.isShow = true;
          this.loading = false;
          this.posterUrl = res.data.posterUrl;
          this.account = res.data.email;
          if (res.code == 200) {
            this.isData = true;
          } else {
            this.isData = false;
          }
        })
        .catch((res) => {
          this.isShow = true;
          this.loading = false;
          this.isData = false;
        });
    },
    downloadPic() {
      fetch(this.posterUrl).then((res) => {
        var link = document.createElement("a");

        link.download = "" + ".jpg";

        res.blob().then((file) => {
          link.href = URL.createObjectURL(file);

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        });
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    copyFun(type) {
      // 获取元素内容
      let text = "";
      if (type == 1) {
        text = this.text1;
      }
      if (type == 2) {
        text = this.text2;
      }
      // 创建一个临时的输入框来存储文本
      const input = document.createElement("input");
      input.value = text;

      // 将输入框添加到页面，但不显示
      document.body.appendChild(input);
      input.select(); // 选择输入框的内容

      // 执行复制命令
      document.execCommand("copy");
      this.$message.success("复制成功");
      // 移除临时输入框
      document.body.removeChild(input);
    },
    getClientStatistics() {
      getClientStatistics().then((res) => {
        this.stats[0].value = res.balance;
        this.stats[1].value = res.todayUsage;
        this.MonthlyUsage = res.MonthlyUsage;
        this.posterUrl = res.posterUrl;
      });
    },
    wxPlay() {
      this.wxPlayShow = true;
      const video = this.$refs.videoPlayer;
      video.play(); // 自动播放视频
    },
    aliPlay() {
      this.aliPlayShow = true;
      const video = this.$refs.aliVideoPlayer;
      video.play(); // 自动播放视频
    },
    downloadVideo(type) {
      let url = type == 1 ? this.wxMp4 : this.zfbMp4;
      let filename = type == 1 ? "wx.mp4" : "zfb.mp4";
      fetch(url)
        .then((response) => response.blob()) // 将视频文件转为 Blob 对象
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob); // 创建一个临时的 URL
          link.download = filename || url.split("/").pop(); // 设置下载文件名
          document.body.appendChild(link);
          link.click(); // 触发下载
          document.body.removeChild(link); // 清理 DOM
        })
        .catch((error) => {
          console.error("下载失败:", error);
        });
    },
  },
};
</script>

<style lang="scss">
.title_box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 80px 10px 10px;
  font-weight: 700;
  font-size: 16px;
  margin-left: 0px;
  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 5px;
    height: 20px;
    background: #2a82e4;
  }
}
.text_box {
  font-size: 16px;
  font-weight: 500;
  background-color: #f5f5f5;
  padding: 10px 20px;
  color: #000;
  border-radius: 10px;
  .text_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
  }
}
.copy_text {
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.first_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .first_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 175px;
  }
}
.first_img {
  height: 175px;
  margin: 0 20px;
  border-radius: 5px;
}
.img_box {
  position: relative;
  margin: 0 auto;
  .bofang_img {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    height: 100px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.dashboard-bar-chart {
  position: relative;
  display: flex;
  align-items: flex-start;
  // justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  .el-card__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100% !important;
  }
}
</style>
